import moment from 'moment'
import Model, { fields } from '@thinknimble/tn-models'
import OpportunityEmailTemplate from '../opportunityEmailTemplates'
import User from '../users'

import OpportunityAPI from './api'

export default class Opportunity extends Model {
  static api = OpportunityAPI.create(Opportunity)

  static salesforceGtmCustomerListMemberId = new fields.CharField({ readOnly: true })
  static id = new fields.IntegerField({ readOnly: true })
  static name = new fields.CharField({ readOnly: true })
  static adminIntroSent = new fields.BooleanField()
  static friendlyIntroSent = new fields.BooleanField()
  static founderSelected = new fields.BooleanField()
  static founder = new fields.ModelField({ ModelClass: User })
  static adminUser = new fields.ModelField({ ModelClass: User })
  static friendly = new fields.ModelField({ ModelClass: User })
  static friendlyId = new fields.CharField()
  static friendlyDoesNotKnow = new fields.BooleanField()
  static adminDeclined = new fields.BooleanField()
  static friendlyDeclined = new fields.BooleanField()
  static mainEmailTemplate = new fields.ModelField({
    ModelClass: OpportunityEmailTemplate,
  })
  static customEmailTemplate = new fields.ModelField({
    ModelClass: OpportunityEmailTemplate,
  })
  static friendlyIntroEmailTemplate = new fields.ModelField({
    ModelClass: OpportunityEmailTemplate,
  })
  static mainEmailTemplateId = new fields.CharField()
  static contactId = new fields.CharField()
  static contactSfId = new fields.CharField()
  static contactName = new fields.CharField()
  static contactTitle = new fields.CharField()
  static contactEmail = new fields.CharField()
  static contactCompanyName = new fields.CharField()
  static contactLinkedinProfile = new fields.CharField()
  static contactMailboxes = new fields.CharField()
  static contactClosestInternalEmail = new fields.CharField()
  static contactWarmth = new fields.CharField()
  static salesforceGtmListMemberStage = new fields.CharField()
  static salesforceGtmListMemberFriendlyDidNotRespondDate = new fields.CharField()
  static salesforceGtmListSfId = new fields.CharField()
  static salesforceGtmListId = new fields.CharField()
  static salesforceGtmCustomerListMemberId = new fields.CharField()
  static salesforceGtmListName = new fields.CharField()
  static salesforceGtmListDatetimeCreated = new fields.CharField()
  static salesforceGtmListStatus = new fields.CharField()
  static founderNotes = new fields.CharField()
  static friendlyNotes = new fields.CharField()
  static adminNotes = new fields.CharField()
  static contactLastOutbound = new fields.CharField()
  static contactCompanyIsPortfolioCompany = new fields.BooleanField()
  static contactCompanyPortfolioPointContactName = new fields.CharField()
  static contactGtmListsInLast2Months = new fields.IntegerField()

  get contactLastOutboundMoment() {
    if (this.contactLastOutbound === null || this.contactLastOutbound === '') return null
    return moment(this.contactLastOutbound)
  }

  get salesforceGtmListMemberStageFriendlyDidNotRespondByDateMoment() {
    if (
      this.salesforceGtmListMemberFriendlyDidNotRespondDate === null ||
      this.salesforceGtmListMemberFriendlyDidNotRespondDate === ''
    ) {
      return null
    }
    return moment(this.salesforceGtmListMemberFriendlyDidNotRespondDate)
  }
}
