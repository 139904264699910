import Model, { fields } from '@thinknimble/tn-models'
import User from '../users'

import OpportunityEmailTemplateAPI from './api'

export default class OpportunityEmailTemplate extends Model {
  static api = OpportunityEmailTemplateAPI.create(OpportunityEmailTemplate)

  static id = new fields.CharField({ readOnly: true })
  static lastEdited = new fields.CharField({ readOnly: true })
  static datetimeCreated = new fields.CharField({ readOnly: true })

  static name = new fields.CharField()
  static subject = new fields.CharField()
  static body = new fields.CharField()
  static variables = new fields.CharField()
  static isAdminTemplate = new fields.BooleanField()

  static author = new fields.ModelField({ ModelClass: User })
}
