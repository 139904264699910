import Form, { FormField, RequiredValidator } from '@thinknimble/tn-forms'

export class NewOpportunityEmailTemplateForm extends Form {
  static name = new FormField({ validators: [new RequiredValidator()] })
  static subject = new FormField({ validators: [new RequiredValidator()] })
  // NOTE: body field is further validated by <EmailTemplateValidator />
  // See ./validators.js
  static body = new FormField({ validators: [new RequiredValidator()] })
  static isAdminTemplate = new FormField()
}
