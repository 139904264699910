import { ModelAPI, ApiFilter } from '@thinknimble/tn-models'

import AxiosClient from '../AxiosClient'
import { apiErrorHandler } from '../api'

// NOTE: The AxiosClient is configured to include '/api' in the baseUrl
const OPPORTUNITY_EMAIL_TEMPLATES_ENDPOINT = '/gtm/opportunity-email-templates/'

export default class OpportunityEmailTemplateAPI extends ModelAPI {
  get client() {
    return AxiosClient
  }

  static ENDPOINT = OPPORTUNITY_EMAIL_TEMPLATES_ENDPOINT

  static FILTERS_MAP = {
    ...OpportunityEmailTemplateAPI.FILTERS_MAP,
    authorId: ApiFilter.create({ key: 'author_id' }),
    isAdminTemplate: ApiFilter.create({ key: 'is_admin_template' }),
  }

  create(data) {
    return super.create(data).catch(
      apiErrorHandler({
        apiName: 'OpportunityEmailTemplateAPI.create',
      }),
    )
  }

  update(id, data) {
    const url = `${OPPORTUNITY_EMAIL_TEMPLATES_ENDPOINT}${id}/`
    return this.client
      .patch(url, this.cls.toAPI(data))
      .then((response) => this.cls.fromAPI(response.data))
      .catch(
        apiErrorHandler({
          apiName: 'OpportunityEmailTemplateAPI.update',
        }),
      )
  }

  duplicate(id, populateFriendlyVariables = false) {
    const url = `${OPPORTUNITY_EMAIL_TEMPLATES_ENDPOINT}${id}/duplicate/`
    const data = { populateFriendlyVariables }
    return this.client
      .post(url, this.cls.toAPI(data))
      .then((response) => this.cls.fromAPI(response.data))
      .catch(
        apiErrorHandler({
          apiName: 'OpportunityEmailTemplateAPI.duplicate',
        }),
      )
  }

  delete(id) {
    const url = `${OPPORTUNITY_EMAIL_TEMPLATES_ENDPOINT}${id}/`
    return this.client.delete(url).catch(
      apiErrorHandler({
        apiName: 'OpportunityEmailTemplateAPI.delete',
      }),
    )
  }

  retrieveVariableNames() {
    const url = `${OPPORTUNITY_EMAIL_TEMPLATES_ENDPOINT}variables/`
    return this.client
      .get(url)
      .then((response) => response.data)
      .catch(
        apiErrorHandler({
          apiName: 'OpportunityEmailTemplateAPI.retrieveVariableNames',
        }),
      )
  }

  preview({ emailTemplateId, opportunityId, emailSubject, emailBody }) {
    const url = `${OPPORTUNITY_EMAIL_TEMPLATES_ENDPOINT}preview/`
    const data = { emailTemplateId, opportunityId, emailSubject, emailBody }
    return this.client
      .post(url, this.cls.toAPI(data))
      .then((response) => response.data)
      .catch(
        apiErrorHandler({
          apiName: 'OpportunityEmailTemplateAPI.preview',
        }),
      )
  }

  templateBodyWithFriendlyValues(id) {
    const url = `${OPPORTUNITY_EMAIL_TEMPLATES_ENDPOINT}${id}/body-with-friendly-values/`
    return this.client
      .get(url)
      .then((response) => response.data)
      .catch(
        apiErrorHandler({
          apiName: 'OpportunityEmailTemplateAPI.templateBodyWithFriendlyValues',
        }),
      )
  }
}
