<template>
  <div class="flex flex-col">
    <h1 class="heading">Outstanding Requests.</h1>
    <LoadingSpinner v-if="loading" />
    <div v-else-if="!systemWideOutstanding" class="pt-10">
      <div class="text-9xl">🎊</div>
      <div class="text-xl">No outstanding requests assigned to Friendlies.</div>
    </div>
    <template v-else>
      <p>Overall Outstanding: {{ systemWideOutstanding }}</p>
      <div
        v-for="friendlyData in outstanding"
        :key="friendlyData.friendlyName"
        class="text-left px-12 py-6 border-b"
      >
        <p class="font-bold">{{ friendlyData.friendlyName }}</p>
        <p class="mb-4">Outstanding: {{ friendlyData.totalCount }}</p>
        <div
          v-for="[listName, opportunities] in Object.entries(friendlyData.byList)"
          :key="listName"
          class="pl-12"
        >
          <p>{{ listName }}</p>
          <p v-for="o in opportunities" :key="o" class="pl-12">
            {{ o }}
          </p>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Opportunity from '@/services/opportunities'
import LoadingSpinner from '@/components/LoadingSpinner'

export default {
  name: 'OutstandingIntroRequests',
  components: { LoadingSpinner },
  data() {
    return {
      loading: true,
      outstanding: [],
    }
  },
  async created() {
    const outstanding = await Opportunity.api.retrieveAllOutstanding()
    outstanding.sort((a, b) => a.friendlyName > b.friendlyName)
    this.outstanding = outstanding
    this.loading = false
  },
  computed: {
    systemWideOutstanding() {
      return this.outstanding.reduce((acc, x) => acc + x.totalCount, 0)
    },
  },
}
</script>
